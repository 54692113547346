<template>
    <SementeClasseList />
</template>

<script>
    import SementeClasseList from '../../components/cadastro/SementeClasseList.vue';

    export default {
        name: 'Semente Classe Page',
        components: { SementeClasseList }
    }

</script>
